<template>
  <div class="activity_list">
    <el-card class="box-card flex_row">
      <el-form :inline="true" label-width="88px" label-position="left">
        <el-form-item label="活动名称：">
          <el-input
            v-model="queryInfo.activeName"
            placeholder="请输入活动名称"
            clearable
          />
        </el-form-item>
        <el-form-item label="活动时间：">
          <el-date-picker
            v-model="time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="timeChange"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button class="btn" @click="search">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="addActivityClick">+新建</el-button>
    </el-card>
    <el-card class="over_margin">
      <div class="over_auto" v-if="show">
        <div
          class="activity_list_li"
          v-for="(item, index) in activityList"
          :key="index"
        >
          <el-image
            style="width: 176px; height: 110px"
            :src="item.image"
            fit="fill"
          ></el-image>
          <div class="activity_list_li_right">
            <div class="activity_list_li_title">
              <div class="activity_list_li_title_p">{{ item.activeName }}</div>
              <div class="activity_list_li_title_time">
                时间:{{ item.startTime }} 至 {{ item.endTime }}
              </div>
              <div class="activity_list_li_title_textbtn">
                <el-button
                  @click="workClick(item)"
                  type="text"
                  icon="iconfont icon-jr-icon-zp"
                  class="seach"
                  >作品管理 ({{ item.count }})</el-button
                >
                <el-button
                  type="text"
                  icon="el-icon-document"
                  class="disabled_no"
                  disabled
                  v-if="!item.isDate"
                  >信息收集</el-button
                >
                <el-button
                  type="text"
                  v-else
                  @click="gatheringClick(item)"
                  icon="el-icon-document"
                  class="seach"
                  >信息收集</el-button
                >
              </div>
            </div>
            <div class="activity_list_li_btn">
              <el-button
                plain
                v-if="item.state"
                @click="stateClick(item, '取消发布')"
                class="release width82"
                >已发布</el-button
              >
              <el-button
                type="primary"
                class="width82"
                v-else
                @click="stateClick(item, '发布')"
                >发布活动</el-button
              >
              <el-button
                plain
                class="release"
                v-if="!item.isExist"
                @click="informationTable(item, 0)"
                >+信息表</el-button
              >
              <el-button
                plain
                class="release"
                v-else
                @click="informationTable(item, 1)"
                >编辑信息表</el-button
              >
              <el-button type="text" @click="compileClick(item)"
                >编辑</el-button
              >
              <el-button type="text" @click="closeClick(item)">删除</el-button>
            </div>
          </div>
          <div class="qrcode">
            <el-popover placement="left-start" width="180" trigger="hover">
              <div
                class="pop_image"
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  width: 100%;
                  overflow: hidden;
                "
              >
                <!-- <el-image
                  slot="reference"
                  style="width: 108px; height: 108px; margin: 10px 0"
                  :src="downloadImges"
                ></el-image>
                <el-button size="mini" @click="downLoadImg('QRcode')" plain
                  >下载二维码</el-button
                > -->
                <p style="margin: 20px 0 10px 0" v-if="item.routeLink">
                  {{ item.routeLink }}
                </p>
                <el-button
                  v-if="item.routeLink"
                  size="mini"
                  v-clipboard:copy="item.routeLink"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  plain
                  >复制</el-button
                >
              </div>
              <el-image
                slot="reference"
                style="width: 48px; height: 48px"
                :src="imageUrl"
              ></el-image>
            </el-popover>
          </div>
        </div>
      </div>
      <div class="no_data" v-else>暂无数据~~</div>
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[30, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
  </div>
</template>

<script>
import {
  getActiveList,
  deleteActivity,
  stateActivity,
} from "../../api/activity";
import axios from "axios";
import { pagination } from "@/mixins/pagination";
const defaultQueryInfo = Object.freeze({
  activeName: "", //活动名称
  pageNumber: 0, // 页码
  pageSize: 10, //每页显示个数
  endTime: "", //结束时间
  startTime: "", //开始时间
});
export default {
  name: "Activity",
  mixins: [pagination],
  data() {
    return {
      show: false,
      queryInfo: { ...defaultQueryInfo }, //查询条件
      time: [],
      activityList: [],
      imageUrl:
        "https://elitego-csj-1300257084.cos.ap-shanghai.myqcloud.com/vecto/%E8%89%BE%E5%AA%92.png",
      downloadImges:
        "https://elitego-csj-1300257084.cos.ap-shanghai.myqcloud.com/vecto/QRcode.png",
    };
  },
  created() {
    this.search();
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  methods: {
    //复制链接
    onCopy(e) {
      // 复制成功
      this.$message({
        message: "复制成功！",
        type: "success",
      });
    },
    onError(e) {
      // 复制失败
      this.$message({
        message: "复制失败！",
        type: "error",
      });
    },
    //gatheringClick  路由跳转信息收集表
    gatheringClick(row) {
      this.$router.push({
        path: "/activity/informationTable",
        query: {
          activeName: row.activeName,
          informationId: row.informationId,
        },
      });
    },
    //InformationTable 路由添加信息表
    informationTable(row, index) {
      //index 0->新增  1->编辑
      if (index) {
        this.$router.push({
          path: "/activity/editInformationTable",
          query: {
            activeId: row.id,
            activeName: row.activeName,
            activeType: index,
          },
        });
      } else {
        this.$router.push({
          path: "/activity/addInformationTable",
          query: {
            activeId: row.id,
            activeName: row.activeName,
            activeType: index,
          },
        });
      }
    },
    //路由跳转作品管理
    workClick(row) {
      this.$router.push({
        path: "/activity/worksList",
        query: { activeName: row.activeName, id: row.id },
      });
    },
    //发布活动
    stateClick(row, text) {
      this.$confirm(`确认${text}${row.activeName}吗？`, {
        type: "warning",
      })
        .then(async () => {
          const formData = new FormData();
          formData.append("id", row.id);
          const { data: res } = await stateActivity(formData);
          if (res.success) {
            this.$messageSuccess(`${text}成功`);
          } else {
            this.$messageError(`${text}失败`);
          }
          this.search();
        })
        .catch(() => {});
    },
    //删除活动
    closeClick(row) {
      this.$confirm(`确认删除${row.activeName}吗 ?`, {
        type: "warning",
      })
        .then(async () => {
          const { data: res } = await deleteActivity({ id: row.id });

          if (res.success) {
            this.$messageSuccess(`${row.activeName}删除成功`);
            this.search();
          }
        })
        .catch(() => {});
    },
    //路由跳转编辑活动
    compileClick(row) {
      this.$router.push({
        path: "/activity/editActivityInfo",
        query: { activityType: 1, id: row.id },
      });
    },
    //路由跳转新建活动
    addActivityClick() {
      this.$router.push({
        path: "/activity/addActivityInfo",
        query: { activityType: 0 },
      });
    },
    //下载图片
    downLoadImg(name) {
      axios({
        method: "GET",
        url: `${process.env.VUE_APP_BASE_API}/enrollment/urlStr`,
        params: { urlStr: `${process.env.VUE_APP_BASE_IMG}` },
        responseType: "blob",
        headers: {
          // 设置请求头
          Authorization: this.Authorization,
        },
      })
        .then((res) => {
          let blob = new Blob([res.data]);
          let href = window.URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
          const a = document.createElement("a"); //创建a标签
          a.style.display = "none";
          a.href = href; // 指定下载'链接
          a.download = name + ".png"; //指定下载文件名
          a.click(); //触发下载
          window.URL.revokeObjectURL(a.href); //释放URL对象
          if (document.body.contains(a)) {
            document.body.removeChild(a); //释放标签
          }
        })
        .catch((err) => {
          this.$message.error("图片下载失败");
        });
    },
    // downLoadImg(url, name) {
    //   let image = new Image();
    //   image.setAttribute("crossOrigin", "anonymous");
    //   image.src = url;
    //   image.onload = () => {
    //     let canvas = document.createElement("canvas");
    //     canvas.width = image.width;
    //     canvas.height = image.height;
    //     let ctx = canvas.getContext("2d");
    //     ctx.drawImage(image, 0, 0, image.width, image.height);
    //     canvas.toBlob((blob) => {
    //       let url = URL.createObjectURL(blob);
    //       this.download(url, name);
    //       // 用完释放URL对象
    //       URL.revokeObjectURL(url);
    //     });
    //   };
    // },
    // download(href, name) {
    //   let eleLink = document.createElement("a");
    //   eleLink.download = name;
    //   eleLink.href = href;
    //   eleLink.click();
    //   eleLink.remove();
    // },
    //重置查询条件
    reset() {
      Object.assign(this.queryInfo, defaultQueryInfo);
      this.time = [];
      this.search();
    },
    //分页查询
    async search() {
      const { data: res } = await getActiveList(this.queryInfo);
      if (res.success) {
        if (res.data) {
          this.activityList = res.data.data;
          this.total = res.data.pageTotal;
          this.show = true;
        } else {
          this.activityList = [];
          this.total = 0;
          this.show = false;
        }
      }
    },
    //选择时间
    timeChange(val) {
      console.log(val);
      this.queryInfo.endTime = this.time ? val[1] : "";
      this.queryInfo.startTime = this.time ? val[0] : "";
    },
  },
};
</script>

<style lang="less" scoped>
.activity_list {
  width: 100%;

  /deep/ .iconfont {
    margin-right: 5px;
  }

  .width82 {
    width: 82px !important;
    box-sizing: border-box;
    height: 32px;
  }

  .box-card {
    width: 100%;
    overflow: hidden;
    border: none;
  }

  /deep/ .el-pagination {
    text-align: center;
  }

  /deep/ .el-form-item {
    margin-right: 12px !important;
  }

  /deep/ .el-form-item__content {
    width: auto !important;
  }

  .no_data {
    width: 100%;
    overflow: hidden;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 14px;
    color: #c4c4c4;
  }

  .flex_row {
    /deep/.el-card__body {
      display: flex;
      flex-direction: row;
      align-items: self-start;
    }
  }

  .over_margin {
    margin-top: 18px;
    overflow: auto;

    .over_auto {
      height: 580px;
      overflow: auto;
    }

    .activity_list_li {
      width: 100%;
      overflow: hidden;
      display: flex;
      position: relative;
      // background-repeat: no-repeat;
      // background-position: 100% 0;
      // background-image: url("../../../static/images/qrcode.png");
      flex-direction: row;
      box-sizing: border-box;
      padding: 20px 0;
      border-bottom: 1px solid #efefef;
      align-items: center;
      /deep/ .el-image {
        margin-right: 12px;
      }

      .qrcode {
        width: 48px;
        height: 48px;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
      }

      .activity_list_li_right {
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: space-between;
        align-items: center;

        .activity_list_li_title {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: space-between;
          overflow: hidden;

          .activity_list_li_title_p {
            color: #151515;
            font-size: 18px;
            font-weight: bold;
          }

          .activity_list_li_title_time {
            color: #949090;
            font-size: 14px;
            margin-top: 12px;
            font-weight: normal;
          }

          .activity_list_li_title_textbtn {
            .seach {
              color: #151515;
              font-size: 14px;
              font-weight: normal;

              /deep/ i {
                color: #59d4d8;
              }
            }

            .disabled_no {
              color: #151515;
              font-size: 14px;
              font-weight: normal;
              /deep/ i {
                color: #c4c4c4;
              }
            }
          }
        }
      }

      .activity_list_li_btn {
        margin-left: 20px;
        overflow: hidden;

        .el-button {
          margin-right: 24px;
        }

        /deep/ .el-button--text {
          color: #c4c4c4;
          font-size: 14px;
        }

        /deep/ .release {
          border: 1px solid #8483d0;
          color: #8483d0;
          height: 32px;
          padding: 0 12px;
          font-weight: normal;
        }

        /deep/ .el-button--primary {
          background-color: #8483d0;
          border: 1px solid #8483d0;
          height: 32px;
          padding: 0 12px;
          font-weight: normal;
        }
      }
    }
  }
}
</style>